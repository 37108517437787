@import "core/_colors.less";
@import "core/_settings.less";

// momenteel bij IE9 of lager
.showIfIe9OrUp {
	display: none;
}

.browser-notsupported--wrapper {
	background: @color-brand;
	color: @white;
	font-family: "Ubuntu", sans-serif;
	font-size: 19px;
	line-height: 27px;
	margin-top: 0 !important;
	margin-left: 0 !important;
	text-align: center;
	width: 100% !important;
	height: 100%;
	overflow: hidden;
	padding-top: 2px;

	img,
	a {
		border: 0px;
	}

	img {
		filter: progid:DXImageTransform.Microsoft.AlphaImageLoader();
	}
}

table.layout {
	width: 650px;
	border-collapse: collapse;
	margin: 100px auto;
	color: #fff !important;

	td {
		padding: 0px;
		vertical-align: top;
		text-align: center;
		padding: 7px 3px;
	}

	td.tablet-image {
		width: 450px;
		height: 700px;
	}

	td.horizontal-space {
		height: 50px;
	}

	td.digdag-logo {
		padding-left: 50px;
		width: 450px;
		height: 100px;

		figure {
			background-position: 50% 50%;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("@{images}/logo-parnassys-wit.png");
			display: block;
			height: 100%;
			width: 100%;
			margin: 0;
		}
	}

	td.unsupported-text {
		padding-left: 50px;
		padding-top: 20px;
		height: 120px;
	}

	td.chrome-logo {
		padding-left: 50px;
		width: 112px;
		height: 130px;

		a {
			background-image: url("@{images}/chrome.png");
		}
	}

	td.safari-logo {
		width: 112px;
		height: 130px;

		a {
			background-image: url("@{images}/safari.png");
		}
	}

	td.firefox-logo {
		width: 112px;
		height: 130px;

		a {
			background-image: url("@{images}/firefox.png");
		}
	}

	td.ie-logo {
		width: 112px;
		padding-right: 2px;
		height: 130px;

		a {
			background-image: url("@{images}/ie.png");
		}
	}

	td.parnassys-logo {
		a {
			width: 300px;
			height: 100px;
			background-image: url("@{images}/logo-parnassys-wit.png");
		}
	}

	td.spacer {
		height: 330px;
	}
}

.browser-logos {
	text-align: left;

	a.browser-icon {
		height: 95px;
		width: 95px;
		display: inline-block;
		opacity: 0.6;
		filter: alpha(opacity=60);

		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;

		&:hover {
			opacity: 1;
			filter: alpha(opacity=100);
		}
	}
	small {
		display: block;
		color: white;
	}
}
